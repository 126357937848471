<template>
    <div>
        <h2>
			{{ $t('syndication.gestion_syndicat') }} 
			<font-awesome-icon v-if="can_edit_syndic" :icon="['far', 'pen']" @click="editSyndicationForm" />
		</h2>

		<shutter-panel ref="shutterPanel" />
    </div>
</template>

<script type="text/javascript">
    import Shutter from '@/mixins/Shutter.js'
	import ShutterSyndic from "@/mixins/shutters-manager/Syndic.js"
    import Navigation from "@/mixins/Navigation.js"

	export default {
        name: "StallionSyndication",
        mixins: [Navigation, Shutter, ShutterSyndic],
        props: {
            horse: { type: Object, default: null }
        },
        data() {
            return {
                can_edit_syndic: false,
				events_tab: {
					'ActualSyndicShutter::updateEditionPermission': this.updateEditionPermission
				}
			}
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.setupActualSyndic(this.horse)
            },
			editSyndicationForm() {
				const shutter = this.shutterPanel().get('actual-syndic')
				shutter.editSyndicationForm()
			},
			updateEditionPermission(status) {
				this.can_edit_syndic = status
			}
        },
        watch: {
            horse() {
                this.init_component()
            }
        }
    }
</script>