export default {
    methods: {
        getSyndicShutters(props, callback=() => false) {
            return {
                'actual-syndic': {
                    name: 'actual-syndic',
                    title: this.$t('syndication.actual_syndic'),
                    onOk: callback,
                    cmpPath: 'components/Syndication/Shutters/ActualSyndicShutter',
                    props
                },
                'history-syndic': {
                    name: 'history-syndic',
                    title: this.$t('syndication.history_part'),
                    onOk: callback,
                    cmpPath: 'components/Syndication/Shutters/HistorySyndicShutter',
                    props
                },            
            }
        },

        setupActualSyndic(horse) {
            const shutters = this.getSyndicShutters({
                horse
            })

            this.shutterPanel().open(shutters['actual-syndic'])
        },

        setupHistorySyndic(syndicpart_id) {
            const shutters = this.getSyndicShutters({
                syndicpart_id
            })

            this.shutterPanel().open(shutters['history-syndic'])
        },
    }
}
